@import "style/utils";
@import "style/colors";

html {
  @include DesktopPadding;
  @include HideScrollBar;

  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $White40;
}

body {
  flex: 1;
  background-color: $White40;
}

#__next {
  height: 100%;
  color: #000;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}

#toast-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1001;
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  padding: 0 max(8px, calc($DesktopPaddingHorizontalValue + 8px));

  & > div:last-child {
    margin-top: 8px;
  }
}

#page_wrap {
  position: relative;
  overflow: auto;

  &.with-filters {
    padding-top: 64px;
  }
}

.boxberry_container {
  filter: grayscale(1);

  iframe {
    min-height: 600px;
    @media screen and (max-height: 600px) {
      min-height: 0;
      max-height: 80vh;
    }
  }
}
